<template>
  <component
    ref="component"
    v-bind="localAttrs"
    v-on="localListeners"
    :is="componentName"
    @change="onChange"
    @input="onInput"
  >
    <template
      v-for="slotName of getSlots($slots, $scopedSlots).simple"
      #[slotName]
    >
      <slot :name="slotName" />
    </template>

    <template
      v-for="scopedSlotName of getSlots($slots, $scopedSlots).scoped"
      #[scopedSlotName]="scope"
    >
      <slot :name="scopedSlotName" v-bind="scope" />
    </template>
  </component>
</template>

<script>
import { forOwn } from "@helpers";

export default {
  inheritAttrs: false,
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lazyValue: null,
      tableDate: null,
    };
  },
  components: {
    VDataTable: () => import("vuetify/lib/components/VDataTable/VDataTable"),
    VSelect: () => import("vuetify/lib/components/VSelect/VSelect"),
    VAutocomplete: () =>
      import("vuetify/lib/components/VAutocomplete/VAutocomplete"),
    VCheckbox: () => import("vuetify/lib/components/VCheckbox/VCheckbox"),
    VNavigationDrawer: () =>
      import("vuetify/lib/components/VNavigationDrawer/VNavigationDrawer"),
    VSwitch: () => import("vuetify/lib/components/VSwitch/VSwitch"),
    VChip: () => import("vuetify/lib/components/VChip/VChip"),
    VAvatar: () => import("vuetify/lib/components/VAvatar/VAvatar"),
  },
  methods: {
    focus() {
      return this.$refs.component?.focus && this.$refs.component.focus();
    },
    blur() {
      return this.$refs.component?.blur && this.$refs.component.blur();
    },
    activateMenu() {
      return (
        this.$refs.component?.activateMenu &&
        this.$refs.component.activateMenu()
      );
    },
    validate() {
      return this.$refs.component?.validate && this.$refs.component.validate();
    },
    updateDimensions() {
      return (
        this.$refs.component?.updateDimensions &&
        this.$refs.component.updateDimensions()
      );
    },
    onInput() {
      this.$emit("input", ...arguments);
    },
    onChange() {
      if (["VSwitch", "VCheckbox"].includes(this.componentName)) {
        this.$emit("input", ...arguments);
      }

      this.$emit("change", ...arguments);
    },
    getSlots($slots, $scopedSlots) {
      const simple = [];
      const scoped = [];

      forOwn($scopedSlots, (_, slotName) => {
        if ($slots[slotName]) {
          simple.push(slotName);
        } else {
          scoped.push(slotName);
        }
      });

      return {
        simple,
        scoped,
      };
    },
  },
  computed: {
    localAttrs() {
      const $attrs = { ...this.$attrs };

      if (["VSwitch", "VCheckbox"].includes(this.componentName)) {
        $attrs["input-value"] = $attrs["input-value"] ?? $attrs.value;

        delete $attrs.value;
      }

      return $attrs;
    },
    localListeners() {
      const $listeners = { ...this.$listeners };

      delete $listeners.input;
      delete $listeners.change;

      return $listeners;
    },
  },
  created() {
    this.lazyValue = this.$attrs.value ?? null;

    this.$watch(
      () => {
        return this.lazyValue;
      },
      (lazyValue) => {
        if (this.$refs.component) {
          this.$refs.component.lazyValue = lazyValue;
        }
      }
    );

    this.$watch(
      () => {
        return this.tableDate;
      },
      (tableDate) => {
        if (this.$refs.component) {
          this.$refs.component.tableDate = tableDate;
        }
      }
    );
  },
};
</script>
